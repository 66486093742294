import React, { useState, useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import { oAuthRegistrationAction } from "../redux/actions";
import { connect } from "react-redux";
import IsLoadingHOC from "./IsLoadingHOC";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GooglePlaces from "./GooglePlaces";
import Required from "./Required";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
var validator = require("validator");

const OAuthRegistration = (props) => {
	const { t } = useTranslation();
	const confige = useSelector((state) => state.app.config);
	const history = useHistory();
	const { oAuthRegistrationAction, setLoading } = props;
	const [place, setPlace] = useState({});
	/*[START:INITIALIZE_DECLARATION]*/
	const [data, setData] = useState({'firstName':'','lastName':'','email':'','username':'','birth':'','gender':'',})
	/*[END:INITIALIZE_DECLARATION]*/
	const [validation, setValidation] = useState({});
	const [error, setError] = useState({ firstName: "", email: "", homePhone: "", cellPhone: "", lastName: "", username: "", address: "", address2: "", country: "", state: "", suite: "", zip: "", city: "", shippingInstructions: "", receiveEmail: "", receiveSms: "", instagramHandle: "", facebookHandle: "", tiktokHandle: "", twitterHandle: "", socialSecurityNumber: "" });
	useEffect(() => {
		let items = {};
		if (confige.uiConfig) {
			confige.uiConfig.userFields.forEach((element) => {
				items[element.name] = element.validation;
			});
		}
		if (confige.fingerprinting) {
			if (confige.fingerprinting.enable) {
				const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
				// Get the visitor identifier when you need it.
				fpPromise
					.then((fp) => fp.get())
					.then((result) => {
						let fdata = JSON.parse(JSON.stringify(data));
						fdata.additionalInfo.visitorId = result.visitorId;
						fdata.additionalInfo.requestId = result.requestId;
						setData(fdata);
					});
			}
		}
		setValidation(items);
		// eslint-disable-next-line
	}, [confige, confige.uiConfig]);
	useEffect(() => {
		let userData = props.userData;
		let oldStat = JSON.parse(JSON.stringify(data));
		oldStat.id = userData.id ? userData.id : "";
		if (oldStat.hasOwnProperty("firstName")) {
			oldStat.firstName = userData.firstName ? userData.firstName : "";
		}
		if (oldStat.hasOwnProperty("lastName")) {
			oldStat.lastName = userData.lastName ? userData.lastName : "";
		}
		if (oldStat.hasOwnProperty("email")) {
			oldStat.email = userData.email ? userData.email : "";
		}
		if (oldStat.hasOwnProperty("username")) {
			oldStat.username = userData.username ? userData.username : "";
		}
		if (oldStat.hasOwnProperty("birth")) {
			oldStat.birth = userData.birth ? userData.birth : "";
		}
		if (oldStat.hasOwnProperty("homePhone")) {
			oldStat.homePhone = userData.homePhone ? userData.homePhone : "";
		}
		if (oldStat.hasOwnProperty("cellPhone")) {
			oldStat.cellPhone = userData.cellPhone ? userData.cellPhone : "";
		}
		if (oldStat.hasOwnProperty("address")) {
			oldStat.address = userData.address ? userData.address : "";
		}
		if (oldStat.hasOwnProperty("address2")) {
			oldStat.address2 = userData.address2 ? userData.address2 : "";
		}
		if (oldStat.hasOwnProperty("country")) {
			oldStat.country = userData.country ? userData.country : "";
		}
		if (oldStat.hasOwnProperty("state")) {
			oldStat.state = userData.state ? userData.state : "";
		}
		if (oldStat.hasOwnProperty("suite")) {
			oldStat.suite = userData.suite ? userData.suite : "";
		}
		if (oldStat.hasOwnProperty("city")) {
			oldStat.city = userData.city ? userData.city : "";
		}
		if (oldStat.hasOwnProperty("zip")) {
			oldStat.zip = userData.zip ? userData.zip : "";
		}
		if (oldStat.hasOwnProperty("shippingInstructions")) {
			oldStat.shippingInstructions = userData.shippingInstructions ? userData.shippingInstructions : "";
		}
		if (oldStat.hasOwnProperty("gender")) {
			oldStat.gender = userData.gender ? userData.gender : "";
		}
		if (oldStat.hasOwnProperty("receiveEmail")) {
			oldStat.receiveEmail = userData.receiveEmail ? userData.receiveEmail : "";
		}
		if (oldStat.hasOwnProperty("receiveSms")) {
			oldStat.receiveSms = userData.receiveSms ? userData.receiveSms : "";
		}
		if (oldStat.hasOwnProperty("companyName")) {
			oldStat.companyName = userData.companyName ? userData.companyName : "";
		}
		if (oldStat.hasOwnProperty("instagramHandle")) {
			oldStat.instagramHandle = userData.instagramHandle ? userData.instagramHandle : "";
		}
		if (oldStat.hasOwnProperty("twitterHandle")) {
			oldStat.twitterHandle = userData.twitterHandle ? userData.twitterHandle : "";
		}
		if (oldStat.hasOwnProperty("facebookHandle")) {
			oldStat.facebookHandle = userData.facebookHandle ? userData.facebookHandle : "";
		}
		if (oldStat.hasOwnProperty("tiktokHandle")) {
			oldStat.tiktokHandle = userData.tiktokHandle ? userData.tiktokHandle : "";
		}
		if (oldStat.hasOwnProperty("socialSecurityNumber")) {
			oldStat.socialSecurityNumber = userData.socialSecurityNumber ? userData.socialSecurityNumber : "";
		}
		if (oldStat.hasOwnProperty("latitude")) {
			oldStat.latitude = userData.latitude ? userData.latitude : 0;
		}
		if (oldStat.hasOwnProperty("longitude")) {
			oldStat.longitude = userData.longitude ? userData.longitude : 0;
		}
		setData(oldStat);
	}, [props.userData]);
	const handleChange = (e) => {
		const { name, value } = e.target;
		let errorMessage = "";
		let validationArray = validation[name];
		let check = false;
		for (let key in validationArray) {
			if (key === "minLength") {
				if (value.length < validationArray[key] && value.length !== 0) {
					errorMessage = t("Should not be less then", { count: validationArray[key] });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "maxLength") {
				if (value.length > validationArray[key]) {
					errorMessage = t("Should not be greater then", { count: validationArray[key] });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "mandatory" && validationArray[key]) {
				if (!value.length) {
					errorMessage = t("Should not be empty");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "email" && validationArray[key]) {
				if (!validator.isEmail(value)) {
					errorMessage = t("Please enter a valid email");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "upperCaseReq" && validationArray[key]) {
				if (value.search(/[A-Z]/) < 0) {
					errorMessage = t("Should have atleast one Upper Case latter");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "lowerCaseReq" && validationArray[key]) {
				if (value.search(/[a-z]/) < 0) {
					errorMessage = t("Should have atleast one Lower Case latter");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "specialCharReq" && validationArray[key]) {
				var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
				if (!format.test(value)) {
					errorMessage = t("Should have atleast one special letter");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
		}

		if (!check) {
			setError({ ...error, [name]: "" });
		}
		setData({ ...data, [name]: value });
	};
	const onSubmit = () => {
		let errorArray = {};
		for (let name in data) {
			if (name === "email" || name === "username") {
				continue;
			}
			let value = data[name];
			let validationArray = validation[name];
			let errorMessage = "";
			if (name === "birth" && isNaN(new Date(value)) && value.length !== 0) {
				errorMessage = `${t("Invalid")} ${t("Birth Date")}`;
			} else if (name === "birth") {
				errorMessage = "";
			}
			for (let key in validationArray) {
				if (key === "email" && validationArray[key] && !validator.isEmail(value)) {
					errorMessage = t("Please enter a valid email");
				} else if (key === "minLength" && value.length < validationArray[key] && value.length !== 0) {
					errorMessage = t("Should not be less then", { count: validationArray[key] });
				} else if (key === "maxLength" && value.length > validationArray[key]) {
					errorMessage = t("Should not be greater then", { count: validationArray[key] });
				} else if (key === "mandatory" && validationArray[key] && !value.length) {
					errorMessage = t("Should not be empty");
				} else if (key === "upperCaseReq" && value.search(/[A-Z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Upper Case latter");
				} else if (key === "lowerCaseReq" && value.search(/[a-z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Lower Case latter");
				} else if (key === "specialCharReq" && value.length !== 0) {
					var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
					if (!format.test(value)) {
						errorMessage = t("Should have atleast one special letter");
					}
				}
			}
			errorArray[name] = errorMessage;
		}
		setError(errorArray);
		let check = false;
		for (let key in errorArray) {
			if (errorArray[key]) {
				check = true;
			}
		}
		const newData = { ...data };
		if (!check) {
			if (newData.birth) {
				let datevalue = new Date(newData.birth);
				const dd = String(datevalue.getDate()).padStart(2, "0");
				const mm = String(datevalue.getMonth() + 1).padStart(2, "0");
				const yyyy = datevalue.getFullYear();
				const formatedDate = yyyy + "-" + mm + "-" + dd;
				newData.birth = formatedDate;
			}
			setLoading(true);
			oAuthRegistrationAction(newData, props.updateId)
				.then((response) => {
					toast.success(t("User login successfully"));
					history.push("/");
					setLoading(false);
				})
				.catch((error) => {
					error.map((item) => {
						toast.error(item.message);
					});
					setLoading(false);
				});
		}
	};
	const handleDate = (value) => {
		setData({ ...data, birth: value });
	};
	useEffect(() => {
		if (place.hasOwnProperty("address")) {
			let curStateData = Object.assign({}, data);
			if (curStateData.hasOwnProperty("address")) {
				curStateData.address = place.address;
			}
			if (curStateData.hasOwnProperty("country")) {
				curStateData.country = place.country;
			}
			if (curStateData.hasOwnProperty("state")) {
				curStateData.state = place.state;
			}
			if (curStateData.hasOwnProperty("city")) {
				curStateData.city = place.city;
			}
			if (curStateData.hasOwnProperty("suite")) {
				curStateData.suite = place.street;
			}
			if (curStateData.hasOwnProperty("zip")) {
				curStateData.zip = place.zip;
			}
			if (curStateData.hasOwnProperty("latitude")) {
				curStateData.latitude = place.latitude;
			}
			if (curStateData.hasOwnProperty("longitude")) {
				curStateData.longitude = place.longitude;
			}
			setData(curStateData);
		}
	}, [place]);
	const googleSelectedAddressHandler = (place) => {
		setPlace(place);
	};
	return (
		<Container fluid>
			<div className="register-page">
				<Card>
					<form>
						<h1 className="main-heading text-center">{t("Complete your registration")}</h1>
						{/*[START:FIRSTNAME]*/}
						<div className="form-group row theme-signup-firstname">
							<label htmlFor="first-name" className="col-sm-4 col-form-label">
								<Trans>lang_key_fname</Trans>
								{validation["firstName"] && validation["firstName"].mandatory ? <Required /> : ""}
							</label>
							<div className="col-sm-8">
								<input type="text" value={data.firstName} data-test="register-firstName" name="firstName" onChange={handleChange} className="form-control" id="first-name" placeholder={t("lang_key_fname")} />
								<h5 className="input-error">{error.firstName}</h5>
							</div>
						</div>
						{/*[END:FIRSTNAME]*/}

						{/*[START:LASTNAME]*/}
						<div className="form-group row theme-signup-lastname">
							<label htmlFor="last-name" className="col-sm-4 col-form-label">
								<Trans>lang_key_lname</Trans>
								{validation["lastName"] && validation["lastName"].mandatory ? <Required /> : ""}
							</label>
							<div className="col-sm-8">
								<input type="text" value={data.lastName} data-test="register-lasttName" name="lastName" onChange={handleChange} className="form-control" id="last-name" placeholder={t("lang_key_lname")} />
								<h5 className="input-error">{error.lastName}</h5>
							</div>
						</div>
						{/*[END:LASTNAME]*/}

						{/*[START:USERNAME]*/}
						<div className="form-group row theme-signup-username">
							<label htmlFor="user-name" className="col-sm-4 col-form-label">
								<Trans>lang_key_username</Trans>
								{validation["username"] && validation["username"].mandatory ? <Required /> : ""}
							</label>
							<div className="col-sm-8">
								<input type="text" data-test="register-username" value={data.username} name="username" disabled={true} className="form-control" id="user-name" placeholder={t("lang_key_username")} />
								<h5 className="input-error">{error.username}</h5>
							</div>
						</div>
						{/*[END:USERNAME]*/}

						{/*[START:EMAIL]*/}
						<div className="form-group row theme-signup-email">
							<label htmlFor="email" className="col-sm-4 col-form-label">
								<Trans>lang_key_email</Trans>
								{validation["email"] && validation["email"].mandatory ? <Required /> : ""}
							</label>
							<div className="col-sm-8">
								<input type="email" data-test="register-email" value={data.email} name="email" disabled={true} className="form-control" id="email" placeholder={t("lang_key_email")} />
								<h5 className="input-error">{error.email}</h5>
							</div>
						</div>
						{/*[END:EMAIL]*/}
						
						
						
						
						
						
						
						
						
						
						
						

						
						
						
						{/*[START:ADDRESS2]*/}
						<div className="form-group row theme-signup-address">
							<label htmlFor="address2" className="col-sm-3 col-form-label">
								<Trans>lang_key_add_address2</Trans>
								{validation["address2"] && validation["address2"].mandatory ? <Required /> : ""}
							</label>
							<div className="col-sm-9">
								<input type="text" data-test="register-address2" value={data.address2} name="address2" onChange={handleChange} className="form-control" id="address2" placeholder={t("lang_key_add_address2")} />
								<h5 className="input-error">{error.address2}</h5>
							</div>
						</div>
						{/*[END:ADDRESS2]*/}
						
						
						

						
						
						

						
						
						

						
						
						

						
						
						

						
						
						

						{/*[START:GENDER]*/}
						<div className="form-group row theme-signup-gender">
							<label htmlFor="gender" className="col-sm-4 col-form-label theme-signup-label-gender">
								<Trans>Gender</Trans>
								{validation["gender"] && validation["gender"].mandatory ? <Required /> : ""}
							</label>
							<div className="col-sm-8">
								<select className="form-control" value={data.gender} data-test="signup-gender" name="gender" onChange={handleChange} id="gender" placeholder={t("Gender")}>
									<option value="">
										{t("Select")} {t("Gender")}
									</option>
									<option value="m">{t("Male")}</option>
									<option value="f">{t("Female")}</option>
								</select>
								<h5 className="input-error">{error.gender}</h5>
							</div>
						</div>
						{/*[END:GENDER]*/}

						{/*[START:BIRTH]*/}
						<div className="form-group row theme-signup-birth">
							<label htmlFor="birth" className="col-sm-4 col-form-label theme-signup-label-birth">
								<Trans>Birth Date</Trans>
								{validation["birth"] && validation["birth"].mandatory ? <Required /> : ""}
							</label>
							<div className="col-sm-8">
								<input type="date" value={data.birth} className="form-control" data-test="signup-gender" name="birth" id="birth" placeholder={t("Birth Date")} onChange={(e) => handleDate(e.target.value)} />

								<h5 className="input-error">{error.birth}</h5>
							</div>
						</div>
						{/*[END:BIRTH]*/}

						
						
						

						
						
						

						
						
						

						
						
						

						
						
						
						{/*[START:SSN]*/}
						<div className="form-group row theme-signup-socialSecurityNumber">
							<label htmlFor="companyName" className="col-sm-3 col-form-label">
								<Trans>Social Security Number</Trans>
								{validation["socialSecurityNumber"] && validation["socialSecurityNumber"].mandatory ? <Required /> : ""}
							</label>
							<div className="col-sm-9">
								<input type="text" value={data.socialSecurityNumber} data-test="signup-companyName" name="socialSecurityNumber" onChange={handleChange} className="form-control" id="socialSecurityNumber" placeholder={t("Social Security Number")} />
								<h5 className="input-error">{error.socialSecurityNumber}</h5>
							</div>
						</div>
						{/*[END:SSN]*/}
						<div className="row submit">
							<button type="button" id="register-submit-btn" data-test="register-submit" onClick={onSubmit} className="btn">
								<Trans>Register Now</Trans>
							</button>
						</div>
					</form>
				</Card>
			</div>
		</Container>
	);
};

export default connect(null, { oAuthRegistrationAction })(IsLoadingHOC(OAuthRegistration, "Wait ....."));
