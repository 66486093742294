import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
// eslint-disable-next-line
import { signup, getLockupAction, preRegistrationAction } from '../../redux/actions';
import { connect } from 'react-redux';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GooglePlaces from '../../components/GooglePlaces';
//import Calendar from "react-calendar";
import Required from '../../components/Required';
import Captcha from '../../components/Captcha';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import InputMask from 'react-input-mask';
var validator = require('validator');
const Register = ({ signup, setLoading }) => {
  const { t } = useTranslation();
  const confige = useSelector(state => state.app.config);
  const history = useHistory();
  const [place, setPlace] = useState({});
  const query = new URLSearchParams(window.location.search);
  const referralid = query.get('refCode') ? query.get('refCode') : '';
  const source = query.get('src') ? query.get('src') : '';
  const promoCode = query.get('promoCode') ? query.get('promoCode') : '';
  let [captchaValue, setCaptchaValue] = useState(false);
  let [captchaEnable, setCaptchaEnable] = useState(false);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState({ state: null, token: null, requestBody: null });
  /*[START:INITIALIZE_DECLARATION]*/
  const [data, setData] = useState({'firstName':'','lastName':'','email':'','username':'','birth':'','gender':'',})
  /*[END:INITIALIZE_DECLARATION]*/
  const [validation, setValidation] = useState({});
  const [error, setError] = useState({
    firstName: '',
    email: '',
    homePhone: '',
    cellPhone: '',
    lastName: '',
    username: '',
    address: '',
    address2: '',
    country: '',
    state: '',
    suite: '',
    zip: '',
    city: '',
    shippingInstructions: '',
    receiveEmail: '',
    receiveSms: '',
    gender: '',
    passwordConfirm: '',
    password: '',
    instagramHandle: '',
    facebookHandle: '',
    tiktokHandle: '',
    twitterHandle: '',
    socialSecurityNumber: '',
    secondaryEmail: '',
  });
  useEffect(() => {
    let items = {};
    let oldState = { ...data, password: '', passwordConfirm: '' };
    setData(oldState);
    if (confige.uiConfig) {
      confige.uiConfig.userFields.forEach(element => {
        items[element.name] = element.validation;
      });
      items.password = confige.uiConfig.passwordRule;
      items.passwordConfirm = confige.uiConfig.passwordRule;
      if (confige.fingerprinting) {
        if (confige.fingerprinting.enable) {
          const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
          // Get the visitor identifier when you need it.
          fpPromise
            .then(fp => fp.get())
            .then(result => {
              let fdata = JSON.parse(JSON.stringify(data));
              fdata.additionalInfo.visitorId = result.visitorId;
              fdata.additionalInfo.requestId = result.requestId;
              setData(fdata);
            });
        }
      }
    }
    if (confige.captchaConfig) {
      if (confige.captchaConfig.registrationCaptcha) {
        setCaptchaEnable(true);
      }
    }
    setValidation(items);
    // eslint-disable-next-line
  }, [confige, confige.uiConfig]);
  React.useEffect(() => {
    if (captchaEnable && refreshReCaptcha.state === 'v3' && refreshReCaptcha.token) {
      let requestBody = refreshReCaptcha.requestBody;
      requestBody['captchaVal'] = refreshReCaptcha.token;
      apiHandler(requestBody);
    }
  }, [refreshReCaptcha]);
  const handleChange = e => {
    const { name, value } = e.target;
    //console.log(name, validation);
    let errorMessage = '';
    let validationArray = validation[name];
    let check = false;
    for (let key in validationArray) {
      if (key === 'minLength') {
        if (value.length < validationArray[key] && value.length !== 0) {
          errorMessage = `${t('Should not be less then', { count: validationArray[key] })}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'maxLength') {
        if (value.length > validationArray[key]) {
          errorMessage = `${t('Should not be greater then', { count: validationArray[key] })}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'mandatory' && validationArray[key]) {
        if (!value.length) {
          errorMessage = `${t('Should not be empty')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'hasNumeric' && validationArray[key] && value.search(/[0-9]/) < 0) {
        errorMessage = `${t('Should be contain number')}.`;
        setError({ ...error, [name]: errorMessage });
        check = true;
      }
      if (key === 'hasAlpha' && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
        errorMessage = `${t('Should be contain alphabets')}.`;
        setError({ ...error, [name]: errorMessage });
        check = true;
      }
      if (key === 'email' && validationArray[key]) {
        if (!validator.isEmail(value)) {
          errorMessage = `${t('Please enter a valid email')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'upperCaseReq' && validationArray[key]) {
        if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have atleast one Upper Case latter')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'lowerCaseReq' && validationArray[key]) {
        if (value.search(/[a-z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have atleast one Lower Case latter')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'specialCharReq' && validationArray[key]) {
        // eslint-disable-next-line
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (!format.test(value) && value.length !== 0) {
          errorMessage = `${t('Should have atleast one special letter')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }

      if (name === 'passwordConfirm') {
        if (data.password !== value) {
          setError({ ...error, passwordConfirm: `${t('Password and confirm password should be same')}.` });
        }
      }
    }

    if (!check) {
      setError({ ...error, [name]: '' });
    }
    setData({ ...data, [name]: value });
  };
  const onSubmit = async () => {
    let errorArray = {};
    for (let name in data) {
      let value = data[name];
      let validationArray = validation[name];
      let errorMessage = '';
      if (name === 'birth' && isNaN(new Date(value)) && value.length !== 0) {
        errorMessage = `${t('Invalid')} ${t('Birth Date')}.`;
      } else if (name === 'birth') {
        errorMessage = '';
      }
      for (let key in validationArray) {
        if (key === 'email' && validationArray[key] && !validator.isEmail(value)) {
          errorMessage = `${t('Please enter a valid email')}.`;
        } else if (key === 'minLength' && value.length < validationArray[key] && value.length !== 0) {
          errorMessage = `${t('Should not be less then', { count: validationArray[key] })}.`;
        } else if (key === 'maxLength' && value.length > validationArray[key]) {
          errorMessage = `${t('Should not be greater then', { count: validationArray[key] })}.`;
        } else if (key === 'mandatory' && validationArray[key] && !value.length) {
          errorMessage = `${t('Should not be empty')}.`;
        } else if (key === 'upperCaseReq' && value.search(/[A-Z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have atleast one Upper Case latter')}.`;
        } else if (key === 'lowerCaseReq' && value.search(/[a-z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have atleast one Lower Case latter')}.`;
        } else if (key === 'hasNumeric' && validationArray[key] && value.search(/[0-9]/) < 0) {
          errorMessage = `${t('Should be contain number')}.`;
        } else if (key === 'hasAlpha' && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
          errorMessage = `${t('Should be contain alphabets')}.`;
        } else if (key === 'specialCharReq' && value.length !== 0) {
          // eslint-disable-next-line
          var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
          if (!format.test(value)) {
            errorMessage = `${t('Should have atleast one special letter')}.`;
          }
        }
      }
      errorArray[name] = errorMessage;

      if (name === 'password' || name === 'passwordConfirm') {
        if (data['password'] !== data['passwordConfirm']) {
          errorMessage = `${t('Password and confirm password should be same')}.`;
        }
        errorArray['passwordConfirm'] = errorMessage;
      }
    }
    setError(errorArray);
    let check = false;
    for (let key in errorArray) {
      if (errorArray[key]) {
        check = true;
      }
    }
    if (data.secondaryEmail) {
      if (!validator.isEmail(data.secondaryEmail)) {
        let errorMessage = `${t('Please enter a valid email')}.`;
        setError({ ...error, secondaryEmail: errorMessage });
        check = true;
      }
      if (data.secondaryEmail === data.email) {
        let errorMessage = `${t('Secondary email should not be the same as Primary email')}.`;
        setError({ ...error, secondaryEmail: errorMessage });
        check = true;
      }
    }

    if (!captchaValue && captchaEnable) {
      toast.error(`${t('Error')}! ${t('Please select captcha')}.`);
      return false;
    }
    const newData = { ...data };
    if (captchaEnable) {
      newData['captchaVal'] = captchaValue;
    }
    delete newData.passwordConfirm;
    if (!check) {
      if (newData.birth) {
        let datevalue = new Date(newData.birth);
        const dd = String(datevalue.getDate()).padStart(2, '0');
        const mm = String(datevalue.getMonth() + 1).padStart(2, '0');
        const yyyy = datevalue.getFullYear();
        const formatedDate = yyyy + '-' + mm + '-' + dd;
        newData.birth = formatedDate;
      }
      if (!newData.hasOwnProperty('additionalInfo')) {
        newData['additionalInfo'] = {};
      }
      if (promoCode) {
        newData['additionalInfo']['promoCode'] = promoCode;
      }
      if (source) {
        newData['additionalInfo']['source'] = source;
      }
      setLoading(true);
      if (captchaEnable && confige.captchaConfig && confige.captchaConfig.recaptchaVersion === 'V3') {
        setRefreshReCaptcha({ ...refreshReCaptcha, state: 'init', requestBody: newData });
        return;
      }
      apiHandler(newData);
      /* try {
				let signupCall = true;
				if (confige.usePreRegistration) {
					signupCall = false;
					let preRegistrationResp = await preRegistrationAction(newData);
					if (preRegistrationResp.data) {
						if (preRegistrationResp.data.valid) {
							signupCall = true;
							newData.additionalInfo.token = preRegistrationResp.data.token;
						} else {
							setLoading(false);
							toast.error(preRegistrationResp.data.errorReason);
						}
					} else {
						setLoading(false);
						toast.error(`${t("Something went wrong")}.`);
					}
				}
				if (signupCall) {
					let signupResp = await signup(newData, referralid);
					toast.success(signupResp);
					history.push("/login");
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
				if (typeof error === "object") {
					error.forEach((item) => {
						toast.error(item.message);
					});
				} else {
					toast.error(`${t("Something went wrong")}.`);
				}
			} */
    }
  };
  const apiHandler = async newData => {
    try {
      let signupCall = true;
      if (confige.usePreRegistration) {
        signupCall = false;
        let preRegistrationResp = await preRegistrationAction(newData);
        if (preRegistrationResp.data) {
          if (preRegistrationResp.data.valid) {
            signupCall = true;
            newData.additionalInfo.token = preRegistrationResp.data.token;
          } else {
            setLoading(false);
            toast.error(preRegistrationResp.data.errorReason);
          }
        } else {
          setLoading(false);
          toast.error(`${t('Something went wrong')}.`);
        }
      }
      if (signupCall) {
        let signupResp = await signup(newData, referralid);
        toast.success(signupResp);
        history.push('/login');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (typeof error === 'object') {
        error.forEach(item => {
          toast.error(item.message);
        });
      } else {
        toast.error(`${t('Something went wrong')}.`);
      }
    }
  };
  const handleDate = value => {
    setData({ ...data, birth: value });
  };
  useEffect(() => {
    if (place.hasOwnProperty('address')) {
      let err = { ...error };
      let curStateData = Object.assign({}, data);
      if (curStateData.hasOwnProperty('address')) {
        curStateData.address = place.address;
        err.address = '';
      }
      if (curStateData.hasOwnProperty('country')) {
        curStateData.country = place.country;
      }
      if (curStateData.hasOwnProperty('state')) {
        curStateData.state = place.state;
        err.state = '';
      }
      if (curStateData.hasOwnProperty('city')) {
        curStateData.city = place.city;
        err.city = '';
      }
      if (curStateData.hasOwnProperty('suite')) {
        curStateData.suite = place.street;
        err.suite = '';
      }
      if (curStateData.hasOwnProperty('zip')) {
        curStateData.zip = place.zip;
        err.zip = '';
      }
      if (curStateData.hasOwnProperty('latitude')) {
        curStateData.latitude = place.latitude;
      }
      if (curStateData.hasOwnProperty('longitude')) {
        curStateData.longitude = place.longitude;
      }
      setData(curStateData);
      setError(err);
    }
    // eslint-disable-next-line
  }, [place]);
  const googleSelectedAddressHandler = place => {
    setPlace(place);
  };
  const formatChars = {
    '1': '[12]',
    '0': '[0123456789]',
  };

  return (
    <div className="page">
      <div className="container">
        <Card>
          <form role="form">
            <h1 className="main-heading">{t('Register Now')}</h1>
            <div className="row">
              {/*[START:FIRSTNAME]*/}
              <div className="form-group col-md-6 theme-signup-firstname">
                <label htmlFor="first-name" className="col-sm-12 col-form-label">
                  <Trans>lang_key_fname</Trans>
                  {validation['firstName'] && validation['firstName'].mandatory ? <Required /> : ''}
                </label>
                <div className="col-sm-12">
                  <input type="text" data-test="register-firstName" name="firstName" onChange={handleChange} className="form-control" id="first-name" placeholder={`${t('Enter ')}${t('lang_key_fname')}`} aria-required="true" aria-autocomplete="name" />
                  <span className="input-error" role="alert">
                    {error.firstName}
                  </span>
                </div>
              </div>
              {/*[END:FIRSTNAME]*/}

              {/*[START:LASTNAME]*/}
              <div className="form-group col-md-6 theme-signup-lastname">
                <label htmlFor="last-name" className="col-sm-12 col-form-label">
                  <Trans>lang_key_lname</Trans>
                  {validation['lastName'] && validation['lastName'].mandatory ? <Required /> : ''}
                </label>
                <div className="col-sm-12">
                  <input type="text" data-test="register-lasttName" name="lastName" onChange={handleChange} className="form-control" id="last-name" placeholder={`${t('Enter ')}${t('lang_key_lname')}`} aria-required="true" aria-autocomplete="family name" />
                  <span className="input-error" role="alert">
                    {error.lastName}
                  </span>
                </div>
              </div>
              {/*[END:LASTNAME]*/}

              {/*[START:USERNAME]*/}
              <div className="form-group col-md-6 theme-signup-username">
                <label htmlFor="user-name" className="col-sm-12 col-form-label">
                  <Trans>lang_key_username</Trans>
                  {validation['username'] && validation['username'].mandatory ? <Required /> : ''}
                </label>
                <div className="col-sm-12">
                  <input type="text" data-test="register-username" name="username" onChange={handleChange} className="form-control" id="user-name" placeholder={`${t('Enter ')}${t('lang_key_username')}`} aria-required="true" aria-autocomplete="user" />
                  <span className="input-error" role="alert">
                    {error.username}
                  </span>
                </div>
              </div>
              {/*[END:USERNAME]*/}

              {/*[START:EMAIL]*/}
              <div className="form-group col-md-6 theme-signup-email">
                <label htmlFor="email" className="col-sm-12 col-form-label">
                  <Trans>lang_key_email</Trans>
                  {validation['email'] && validation['email'].mandatory ? <Required /> : ''}
                </label>
                <div className="col-sm-12">
                  <input type="email" data-test="register-email" name="email" onChange={handleChange} className="form-control" id="email" placeholder={`${t('Enter ')}${t('lang_key_email')}`} aria-required="true" aria-autocomplete="email" />
                  <span className="input-error" role="alert">
                    {error.email}
                  </span>
                </div>
              </div>
              {/*[END:EMAIL]*/}
              {/*[START:EMAIL]*/}
              <div className="form-group col-md-6 theme-signup-email">
                <label htmlFor="email" className="col-sm-12 col-form-label">
                  <Trans>SECONDARY EMAIL</Trans>
                  {validation['secondaryEmail'] && validation['secondaryEmail'].mandatory ? <Required /> : ''}
                </label>
                <div className="col-sm-12">
                  <input type="email" data-test="register-secondaryEmail" name="secondaryEmail" onChange={handleChange} className="form-control" id="secondaryEmail" placeholder={`${t('Enter ')}${t('Secondary Email')}`} aria-required="true" aria-autocomplete="secondaryEmail" />
                  <span className="input-error" role="alert">
                    {error.secondaryEmail}
                  </span>
                </div>
              </div>
              {/*[END:EMAIL]*/}
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              {/*[START:ADDRESS2]*/}
              <div className="form-group col-md-6 theme-signup-address">
                <label htmlFor="address2" className="col-sm-12 col-form-label">
                  <Trans>lang_key_add_address2</Trans>
                  {validation['address2'] && validation['address2'].mandatory ? <Required /> : ''}
                </label>
                <div className="col-sm-12">
                  <input type="text" data-test="register-address2" value={data.address2} name="address2" className="form-control" id="address2" onChange={handleChange} placeholder={`${t('Enter ')}${t('lang_key_add_address2')}`} aria-autocomplete="street" />
                  <span className="input-error" role="alert">
                    {error.address2}
                  </span>
                </div>
              </div>
              {/*[END:ADDRESS2]*/}
              
              
              

              
              
              

              
              
              

              
              
              

              
              
              

              
              
              

              {/*[START:GENDER]*/}
              <div className="form-group col-md-6 theme-signup-gender">
                <label htmlFor="gender" className="col-sm-12 col-form-label theme-signup-label-gender">
                  <Trans>Gender</Trans>
                  {validation['gender'] && validation['gender'].mandatory ? <Required /> : ''}
                </label>
                <div className="col-sm-12">
                  <select className="form-control" data-test="signup-gender" name="gender" onChange={handleChange} id="gender" placeholder={t('Gender')} aria-required="true">
                    <option value="">
                      {t('Select')} {t('Gender')}
                    </option>
                    <option value="m">{t('Male')}</option>
                    <option value="f">{t('Female')}</option>
                  </select>
                  <span className="input-error" role="alert">
                    {error.gender}
                  </span>
                </div>
              </div>
              {/*[END:GENDER]*/}

              {/*[START:BIRTH]*/}
              <div className="form-group col-md-6 theme-signup-birth">
                <label htmlFor="birth" className="col-sm-12 col-form-label theme-signup-label-birth">
                  <Trans>Birth Date</Trans>
                  {validation['birth'] && validation['birth'].mandatory ? <Required /> : ''}
                </label>
                <div className="col-sm-12">
                  <InputMask
                    className="form-control show-placeholder"
                    mask="00/00/1000"
                    maskChar={''}
                    value={data.birth}
                    id="birth"
                    formatChars={formatChars}
                    onChange={e => {
                      handleDate(e.target.value);
                    }}
                    placeholder="MM/DD/YYYY"
                    aria-required="true"
                    aria-autocomplete="DOB"></InputMask>
                  <span className="input-error" role="alert">
                    {error.birth}
                  </span>
                </div>
              </div>
              {/*[END:BIRTH]*/}

              
              
              

              
              
              

              
              
              

              
              
              

              
              
              
              {/*[START:SSN]*/}
              <div className="form-group col-md-6 theme-signup-socialSecurityNumber">
                <label htmlFor="socialSecurityNumber" className="col-sm-12 col-form-label">
                  <Trans>Social Security Number</Trans>
                  {validation['socialSecurityNumber'] && validation['socialSecurityNumber'].mandatory ? <Required /> : ''}
                </label>
                <div className="col-sm-12">
                  <InputMask className="form-control" mask="999-99-9999" maskChar={''} value={data.socialSecurityNumber} id="socialSecurityNumber" name="socialSecurityNumber" onChange={handleChange} aria-label="Create Social Security Number" placeholder={`${t('Enter ')}${t('Social Security Number')}`} aria-required="true"></InputMask>
                  <span className="input-error" role="alert">
                    {error.socialSecurityNumber}
                  </span>
                </div>
              </div>
              {/*[END:SSN]*/}
              
              
              
              {/*[START:GOOGLECAPTCHA]*/}
              {captchaEnable ? (
                <div className="form-group col-md-6">
                  <label htmlFor="g-recaptcha-response" className="col-sm-12 invisible" aria-hidden="true">
                    Google captcha
                  </label>
                  <div className="col-sm-12">
                    <Captcha reset={refreshReCaptcha.state} parentCallback={setCaptchaValue} v3Callback={setRefreshReCaptcha} />
                  </div>
                </div>
              ) : (
                ''
              )}
              {/*[END:GOOGLECAPTCHA]*/}
              <div className="col-md-12 text-center mt-5">
                <button type="button" id="register-submit-btn" data-test="register-submit" onClick={onSubmit} className="btn btn-primary">
                  <Trans>Create Account</Trans>
                </button>
              </div>
              <div className="col-md-12 forgot-pass-links text-center mt-5">
                <a href="/login" id="login-href">
                  <Trans>click here to login your account.</Trans>
                </a>
              </div>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default connect(null, { signup })(IsLoadingHOC(Register, 'Wait .....'));
