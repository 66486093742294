import React from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import TopRight from './TopRight';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { uiconfig } from '../redux/actions';
import { connect } from 'react-redux';

const Menu = ({ data, uiconfig }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector(({ user }) => user);
  React.useEffect(() => {
    (async () => {
      uiconfig()
        .then(response => {
          if (!response.data || response.httpCode !== 200) {
            //history.push("/maintenance");
          }
        })
        .catch(error => {
          console.log(error);
          history.push('/maintenance');
        });
    })();
  }, [uiconfig]);
  return (
    <Fragment>
      <button id="button-navbar-toggler" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span id="span-navbar-toggler-icon" className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mx-auto mt-2 mt-lg-0">
          {/*[START:VALIDATEPURCHASE]*/}
          <li className="nav-item dropdown">
            <a id="href-validate-purchase" className="nav-link dropdown-toggle" href="#/" data-toggle="dropdown" aria-expanded="false">
              {t('Validate Purchase')}
            </a>
            <ul className="dropdown-menu">
              {/*[START:SUBMITCODE]*/}
              <li className="nav-item">
                <Link className="nav-link" id="href-submit-code" to="/validate-code">
                  {t('Submit Code')}
                </Link>
              </li>
              {/*[END:SUBMITCODE]*/}

              {/*[START:DIGITALMOVIE]*/}
              <li className="nav-item">
                <a className="nav-link" id="href-digital-movie" href="/">
                  {t('Digital Movie')}
                </a>
              </li>
              {/*[END:DIGITALMOVIE]*/}

              {/*[START:RECEIPTUPLOAD]*/}
              <li className="nav-item">
                <Link className="nav-link" id="href-receipt-upload" to="/upload-receipt">
                  {t('Receipt Upload')}
                </Link>
              </li>
              {/*[END:RECEIPTUPLOAD]*/}

              {/*[START:ELIGIBLEMOVIES]*/}
              <li className="nav-item">
                <Link className="nav-link" id="href-eligible-products" to="/eligible-product">
                  {t('Eligible Products')}
                </Link>
              </li>
              {/*[END:ELIGIBLEMOVIES]*/}
            </ul>
          </li>
          {/*[END:VALIDATEPURCHASE]*/}

          {/*[START:REWARDS]*/}
          <li className="nav-item">
            <Link className="nav-link" id="href-rewards" to="/rewards">
              {t('Rewards')}
            </Link>
          </li>
          {/*[END:REWARDS]*/}

          
          
          

          {/*[START:EARNPOINTS]*/}
          <li className="nav-item">
            <Link className="nav-link" id="href-earn-points" to="/earnpoints">
              {t('Earn Points')}
            </Link>
          </li>
          {/*[END:EARNPOINTS]*/}

          {/*[START:ALLACCESS]*/}
          {user.accessToken && (
            <li className="nav-item dropdown">
              <a id="href-all-access" className="nav-link dropdown-toggle" href="#/" data-toggle="dropdown" aria-expanded="false">
                {t('All Access')}
              </a>
              <ul className="dropdown-menu">
                {/*[START:ALLMYACCESS]*/}
                <li className="nav-item">
                  <a className="nav-link" id="href-all-my-access" href="/">
                    {t('All My-Access')}
                  </a>
                </li>
                {/*[END:ALLMYACCESS]*/}
                {/*[START:MYMOVIEFEATURES]*/}
                <li className="nav-item">
                  <a className="nav-link" id="href-my-movie-features" href="/">
                    {t('My Movie Features')}
                  </a>
                </li>
                {/*[END:MYMOVIEFEATURES]*/}
              </ul>
            </li>
          )}
          {/*[END:ALLACCESS]*/}

          {/*[START:FANZONE]*/}
          <li className="nav-item">
            <a className="nav-link" id="href-fan-zone" href="#/">
              {t('Fan Zone')}
            </a>
          </li>
          {/*[END:FANZONE]*/}
        </ul>
        {/* My All-Access , Points History, My Rewards, Profile, Connect Movie ANYHERE, Logout */}
        <ul className="navbar-nav me-0 mt-2 mt-lg-0">
          <TopRight />
        </ul>
      </div>
    </Fragment>
  );
};

export default connect(null, { uiconfig })(Menu);
